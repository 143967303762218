<!--
 * @Description: 运维管理 运维管理 运维工单管理 operateWorkList
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-16 11:51:55
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--地图渲染 -->
    <div class="map-container">
      <div id="map"></div>
    </div>
    <!-- 顶部 -->
    <el-row class="topContent">
      <el-row class="topContent-item">
        <el-row class="topContent-item-number"
                :style="colorJson[theme].color">
          {{ listStatus[0].count }}
        </el-row>
        <el-row class="topContent-item-text">工单总数</el-row>
      </el-row>
      <el-row class="topContent-item">
        <el-row class="topContent-item-number"
                :style="colorJson[theme].color">
          {{ listStatus[1].count }}
        </el-row>
        <el-row class="topContent-item-text">待确认工单</el-row>
      </el-row>
      <el-row class="topContent-item">
        <el-row class="topContent-item-number"
                :style="colorJson[theme].color">
          {{ listStatus[2].count }}
        </el-row>
        <el-row class="topContent-item-text">待处理工单</el-row>
      </el-row>
      <el-row class="topContent-item">
        <el-row class="topContent-item-number"
                :style="colorJson[theme].color">
          {{ listStatus[3].count }}
        </el-row>
        <el-row class="topContent-item-text">待复核工单</el-row>
      </el-row>
      <el-row class="topContent-item">
        <el-row class="topContent-item-number"
                :style="colorJson[theme].color">
          {{ listStatus[4].count }}
        </el-row>
        <el-row class="topContent-item-text">已完成工单</el-row>
      </el-row>
    </el-row>
    <!-- 左侧 -->
    <el-row class="leftContent"
            :style="colorJson[theme].backColor">
      <!-- 工单编号输入框 -->
      <el-row class="leftContent-input">
        <el-input placeholder="请输入工单编号"
                  v-model="searchForm.workId">
          <i slot="prefix"
             style="line-height: 40px; cursor: pointer"
             class="el-input__icon el-icon-search"
             @click="queryWorkTicketList"></i>
        </el-input>
      </el-row>
      <!-- 设备选择 -->
      <el-row class="leftContent-selectChoose">
        <el-checkbox-group v-model="deviceCheckBox"
                           @change="handleChangeCheckBox"
                           size="small">
          <el-checkbox v-for="(item, index) in deviceTypeList"
                       :label="item.code"
                       :value="item.code"
                       :key="index"
                       :style="colorJson[theme].checkColor"
                       border>{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-row>
      <!-- 横线 -->
      <el-row class="leftContent-line"
              :style="colorJson[theme].checkColor"></el-row>
      <!-- 工单列表 -->
      <el-row class="leftContent-listContent">
        <!-- 顶部标题切换 ⬇⬇⬇ -->
        <el-row class="listType">
          <div :class="[
              item.key == tabsValue ? 'listType-block-select' : '',
              'listType-block',
            ]"
               :style="colorJson[theme].changeBack"
               v-for="(item, idx) in listStatus"
               :key="idx"
               @click="changeStatus(item.key)">
            {{ item.label }}({{ item.count }})
          </div>
        </el-row>
        <el-row>
          <!-- 具体的列表内容 ⬇⬇⬇ -->
          <el-row class="leftContent-listContent-item"
                  v-for="(item, index) in listJson[tabsValue == '' ? 0 : tabsValue]"
                  :label="item.code"
                  :value="item.code"
                  :key="index"
                  @click.native="queryDeviceInfo(item)">
            <el-row class="lineOne">
              <el-col :span="19"
                      class="lineOne-left">
                {{ item.workTicketSequence }}</el-col>
              <el-col :span="5"
                      class="lineOne-right"
                      @click.native="handleClickDetail(item)">详情 >></el-col>
            </el-row>
            <el-row class="lineTwo">
              <span class="label">问题类型</span>
              <span class="value">{{ item.deviceFaultType }}</span>
            </el-row>
            <el-row class="lineThree">
              <span class="label">设备类型</span>
              <span class="value">{{ item.deviceType }}</span>
            </el-row>
            <el-row class="lineFour">
              <span class="label">生成时间</span>
              <span class="value">{{ item.ticketCreateTime }}</span>
            </el-row>
            <el-row class="lineFive">
              <div class="popTimeLine">
                <div class="timeLineItemBox">
                  <div class="timeLineLine"></div>
                  <div class="timeLineItem"
                       v-for="(data, i) in statusList"
                       :key="i">
                    <div class="poitBg"></div>
                    <div :class="
                        item.ticketStatusCode == data.key ? 'poitGreen' : 'poit'
                      "></div>
                    <div class="tip">{{ data.label }}</div>
                  </div>
                </div>
              </div>
            </el-row>
            <el-row class="leftContent-line"></el-row>
          </el-row>
        </el-row>
        <div class="pagination">
          <el-pagination layout="prev,pager,next"
                         :page-size="pageSize"
                         :total="total"
                         :current-page="pageNum"
                         :background="true"
                         @current-change="getVal"></el-pagination>
        </div>
      </el-row>
    </el-row>
    <!-- 工单详情 -->
    <el-dialog title="工单详情"
               width="50%"
               style="left:250px;margin-top:20px"
               append-to-body
               :modal="false"
               v-dialogDrag
               :visible.sync="waitConfirmwaitConfirm"
               :close-on-click-modal="false">
      <div class="dialogDetails"
           v-loading="dialogLoading"
           element-loading-text="加载中"
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-timeline>
          <!-- 工单生成 -->
          <el-timeline-item>
            <div class="dialogDetailsBlock">
              <div class="dialogDetailsTitle">
                <div class="dialogDetailsTitleLeft">工单生成信息</div>
                <div @click="switchVisible('basicVisible')">
                  <template v-if="basicVisible">
                    收起
                    <i class="el-icon-arrow-up"></i>
                  </template>
                  <template v-else>
                    展开
                    <i class="el-icon-arrow-down"></i>
                  </template>
                </div>
              </div>
              <div v-if="basicVisible">
                <el-row class="dialogDetailsRow"
                        style="margin:20px 0">
                  <span class="dialogDetailsRowTitle">工单编号:</span>
                  <span class="dialogDetailsRowContent">{{ selectInfo.workTicketSequence }}</span>
                </el-row>
                <el-row>
                  <el-col class="dialogDetailsRow"
                          v-for="(item, idx) in orderInfo"
                          :key="idx"
                          :span="item.span || 12">
                    <div class="dialogDetailsRowTitle">{{ item.label }}:</div>
                    <div class="dialogDetailsRowContent">
                      {{ item.value }}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-timeline-item>
          <!-- 巡检已确认 -->
          <el-timeline-item color="#337DEF"
                            v-if="selectStatusCode >= 2">
            <div class="dialogDetailsBlock">
              <div class="dialogDetailsTitle">
                <div class="dialogDetailsTitleLeft">巡检已确认</div>
                <div @click="switchVisible('confirmVisible')">
                  <template v-if="confirmVisible">
                    收起
                    <i class="el-icon-arrow-up"></i>
                  </template>
                  <template v-else>
                    展开
                    <i class="el-icon-arrow-down"></i>
                  </template>
                </div>
              </div>
              <!-- 具体的内容 ⬇⬇⬇ -->
              <div v-if="confirmVisible">
                <el-row>
                  <el-col class="dialogDetailsRow"
                          v-for="(item, idx) in confirmInfo"
                          :key="idx"
                          :span="item.span || 12">
                    <div class="dialogDetailsRowTitle">{{ item.label }}:</div>
                    <div v-if="!item.type"
                         class="dialogDetailsRowContent">
                      {{ item.value }}
                    </div>
                    <el-image style="width: 100px; height: 100px"
                              v-if="item.type == 'img' && item.value != ''"
                              :src="$imgBaseUrl + item.value"
                              :preview-src-list="confirmInfoSrcList">
                    </el-image>
                  </el-col>
                </el-row>
              </div>
              <!-- 具体的内容 ⬆⬆⬆ -->
            </div>
          </el-timeline-item>
          <!-- 巡检已处理 -->
          <el-timeline-item color="#F63B24"
                            v-if="selectStatusCode >= 3">
            <div class="dialogDetailsBlock">
              <div class="dialogDetailsTitle">
                <div class="dialogDetailsTitleLeft">巡检已处理</div>
                <div @click="switchVisible('handleVisible')">
                  <template v-if="handleVisible">
                    收起
                    <i class="el-icon-arrow-up"></i>
                  </template>
                  <template v-else>
                    展开
                    <i class="el-icon-arrow-down"></i>
                  </template>
                </div>
              </div>
              <!-- 具体的内容 ⬇⬇⬇ -->
              <div v-if="handleVisible">
                <el-row>
                  <el-col class="dialogDetailsRow"
                          v-for="(item, idx) in handleInfo"
                          :key="idx"
                          :span="item.span || 12">
                    <div class="dialogDetailsRowTitle">{{ item.label }}:</div>
                    <div v-if="!item.type"
                         class="dialogDetailsRowContent">
                      {{ item.value }}
                    </div>
                    <el-image style="width: 100px; height: 100px"
                              v-if="item.type == 'img' && item.value != ''"
                              :src="$imgBaseUrl + item.value"
                              :preview-src-list="handleInfoSrcList">
                    </el-image>
                  </el-col>
                </el-row>
              </div>
            </div>
            <!-- 具体的内容 ⬆⬆⬆ -->
          </el-timeline-item>
          <!-- 处理已复核 -->
          <el-timeline-item color="#F69624"
                            v-if="selectStatusCode >= 4">
            <div class="dialogDetailsBlock">
              <div class="dialogDetailsTitle">
                <div class="dialogDetailsTitleLeft">巡检已复核</div>
                <div @click="switchVisible('reviewVisible')">
                  <template v-if="reviewVisible">
                    收起
                    <i class="el-icon-arrow-up"></i>
                  </template>
                  <template v-else>
                    展开
                    <i class="el-icon-arrow-down"></i>
                  </template>
                </div>
              </div>
              <!-- 具体的内容 ⬇⬇⬇ -->
              <div v-if="reviewVisible">
                <el-row>
                  <el-col class="dialogDetailsRow"
                          v-for="(item, idx) in reviewInfo"
                          :key="idx"
                          :span="item.span || 12">
                    <div class="dialogDetailsRowTitle">{{ item.label }}:</div>
                    <div v-if="!item.type"
                         class="dialogDetailsRowContent">
                      {{ item.value }}
                    </div>
                    <img v-if="item.type == 'img' && item.value != ''"
                         :src="$imgBaseUrl + item.value" />
                  </el-col>
                </el-row>
              </div>
            </div>
            <!-- 具体的内容 ⬆⬆⬆ -->
          </el-timeline-item>
          <!-- 工单完成 -->
          <el-timeline-item color="#00D03F"
                            v-if="selectStatusCode >= 4">
            <div style="color: #00d03f">工单完成</div>
          </el-timeline-item>
        </el-timeline>
        <el-row style="display: flex; margin-left: 50px"
                v-if="selectStatusCode === 3">
          <el-button type="info"
                     @click="handleClickWaitPrecheck">
            复核工单
          </el-button>
          <!-- <el-row>
            <el-button type="info"
                       @click="handleClickNoPass">
              处理驳回
            </el-button>
          </el-row>
          <el-row style="margin-left: 50px">
            <el-button type="primary"
                       @click="handleClickPass">
              复核通过
            </el-button>
          </el-row> -->
        </el-row>
      </div>
    </el-dialog>
    <!-- 待复核工单 复核工单-->
    <el-dialog title="复核工单"
               :visible.sync="waitConfirmwaitPrecheckDialog"
               append-to-body
               width="30%"
               top="15%"
               :modal="false"
               :close-on-click-modal="false">
      <el-form :rules="rules"
               :inline="true"
               label-width="80px"
               :model="precheckList"
               ref="precheckList"
               size="small">
        <el-row justify="space-around">
          <el-form-item label="备注"
                        prop="precheckRemark">
            <el-input type="textarea"
                      style="width:300px"
                      :rows="2"
                      placeholder="请输入复核备注"
                      v-model="precheckList.precheckRemark">
            </el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <el-row style="display:flex">
        <el-row>
          <el-button type="info"
                     @click="handleClickNoPass('precheckList')">
            驳回
          </el-button>
        </el-row>
        <el-row style="margin-left: 20px">
          <el-button type="primary"
                     @click="handleClickPass('precheckList')">
            通过
          </el-button>
        </el-row>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如:组件，工具js，第三方插件js，json文件，图片文件等等）
//例如:import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
import $publicFun from "@/utils/publicFun.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          color: 'color:white',
          backColor: "background: #0e355e;border:none",
          checkColor: "color: #70aee8;border:none",
          changeBack: "color: #70aee8"
        },
        light: {
          color: 'color:black',
          backColor: "background:#ffffff;border:none",
          checkColor: "background:#F2F4F8;color:black;border:none",
          changeBack: "color:black"
        }
      },
      map: null, // 地图对象
      lnglat: "", // 点击获取金纬度
      activeName: "1", // tabs页默认显示第一个
      tabsValue: "", // tabs切换绑定值
      deviceTypeList: [], // 设备类型下拉菜单
      deviceCheckBox: [], // 设备多选
      waitConfirm: "0", // 待确认数
      waitConfirmList: [], // 待确认列表
      waitConfirmwaitConfirm: false, // 显示工单弹窗
      waitConfirmwaitPrecheckDialog: false, // 显示复核工单确认弹窗
      // precheckRemark: "",//复核备注
      waitDisPose: "0", // 待处理数
      waitDisPoseList: [], // 待处理列表
      waitRecheck: "0", // 待复核数
      waitRecheckList: [], // 待复核列表
      completedWork: "0", // 已完成数
      completedWorkList: [], // 已完成列表
      searchForm: { workId: "" }, // 按照工单搜索
      pageNum: 1,
      pageSize: 3,
      total: 1, // 初始化分页
      basicVisible: true,
      confirmVisible: true,
      handleVisible: true,
      reviewVisible: true,
      dialogLoading: true,
      selectStatusCode: "",
      marker: "",

      selectInfo: {},
      listJson: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
      },
      statusList: [
        { label: "创建", key: "0" },
        { label: "待确认", key: "1" },
        { label: "待处理", key: "2" },
        { label: "待复核", key: "3" },
        { label: "已完成", key: "4" },
      ],
      listStatus: [
        { label: "全部", count: "0", key: "" },
        { label: "待确认", count: "0", key: "1" },
        { label: "待处理", count: "0", key: "2" },
        { label: "待复核", count: "0", key: "3" },
        { label: "已完成", count: "0", key: "4" },
      ],
      // 工单生成信息 ⬇⬇⬇
      orderInfo: [
        { label: "问题类型", value: "", key: "deviceFaultType" },
        { label: "运维单位", value: "", key: "maintenanceName" },
        { label: "联系电话", value: "", key: "contactPhoneNumber" },
        { label: "工单来源", value: "", key: "ticketType" },
        { label: "创建人员", value: "", key: "creatorName" },
        { label: "创建时间", value: "", key: "ticketCreateTime" },
        {
          label: "问题描述",
          value: "",
          key: "deviceFaultDesc",
          span: 24,
        },
        {
          label: "工单备注",
          value: "",
          key: "ticketRemark",
          span: 24,
        },
      ],
      // 巡检已确认信息 ⬇⬇⬇
      confirmInfo: [
        {
          label: "确认人员",
          value: "",
          key: "confirmorName",
        },
        {
          label: "确认时间",
          value: "",
          key: "ticketConfirmTime",
        },
        {
          label: "确认备注",
          value: "",
          key: "ticketConfirmRemark",
          span: 24,
        },
        {
          type: "img",
          label: "确认图片",
          value: "",
          key: "picUrl",
          span: 24,
        },
      ],
      // 巡检已处理信息 ⬇⬇⬇
      handleInfo: [
        {
          label: "处理人员",
          value: "",
          key: "handlerName",
        },
        {
          label: "处理时间",
          value: "",
          key: "handleTime",
        },
        {
          label: "处理备注",
          value: "",
          key: "handleRemark",
          span: 24,
        },
        {
          type: "img",
          label: "处理图片",
          value: "",
          key: "handlePic",
          span: 24,
        },
      ],
      // 巡检已复核信息 ⬇⬇⬇
      reviewInfo: [
        {
          label: "复核人员",
          value: "",
          key: "checkerName",
        },
        {
          label: "复核时间",
          value: "",
          key: "checkerTime",
        },
        {
          label: "复核备注",
          value: "",
          key: "checkerRemark",
          span: 24,
        },
      ],
      precheckList: [],

      confirmInfoSrcList: [],// 图片预览
      handleInfoSrcList: [],
      rules: {
        precheckRemark: [{ required: true, message: '请输入', trigger: 'blur' }],
      }
    };
  },
  //监听属性 类似于data概念
  computed: {
    watchList () {
      const { deviceCheckBox, tabsValue } = this;
      return { deviceCheckBox, tabsValue };
    },
    ...mapState({
      theme: (state) => state.app.theme,
    })

  },
  //监控data中的数据变化
  watch: {
    watchList (val) {
      this.queryWorkTicketList(val);
    },
    theme () {
      this.initMap();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initMap();
    this.queryTypeList();
    this.queryWorkTicketCount();
    this.queryWorkTicketList();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 数字及列表重制
    resetList () {
      this.queryWorkTicketCount();
      this.queryWorkTicketList();
    },
    // 复核工单
    handleClickWaitPrecheck () {
      this.waitConfirmwaitPrecheckDialog = true
      this.precheckList = {}
    },
    // 处理驳回
    handleClickNoPass (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let info = {
            workTicketSequence: this.selectInfo.workTicketSequence,
            workTicketDealtSequence: this.selectInfo.workTicketDealtSequence,
            checkerRemark: this.precheckList.precheckRemark,
            flag: false,
          };
          this.$operateWorkList.checkWorkTicket(info).then(() => {
            this.waitConfirmwaitConfirm = false;
            this.waitConfirmwaitPrecheckDialog = false
            this.resetList();
          });
        }
      })
    },
    // 复核通过
    handleClickPass (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let info = {
            workTicketSequence: this.selectInfo.workTicketSequence,
            workTicketDealtSequence: this.selectInfo.workTicketDealtSequence,
            checkerRemark: this.precheckList.precheckRemark,
            flag: true,
          };
          this.$operateWorkList.checkWorkTicket(info).then(() => {
            this.waitConfirmwaitConfirm = false;
            this.waitConfirmwaitPrecheckDialog = false
            this.resetList();
          });
        }
      })
    },
    switchVisible (type) {
      this[type] = !this[type];
      this.pageNum = 1;
    },
    // 点击工单详情
    async handleClickDetail (info) {
      this.confirmInfoSrcList = []
      this.handleInfoSrcList = []
      this.dialogLoading = true
      this.selectStatusCode = info.ticketStatusCode;
      // 工单生成信息
      // orderInfo
      // 巡检已确认信息
      // confirmInfo
      // 巡检已处理信息
      // handleInfo
      // 巡检已复核信息
      // reviewInfo
      // eslint-disable-next-line no-unused-vars
      let promiseList = [
        this.queryMaintenance({
          maintenanceId: info.maintenanceId,
        }),
      ];
      // 处理ID存在的话获取处理记录
      if (info.workTicketDealtSequence) {
        promiseList.push(
          this.queryWorkTicketDealt({
            workTicketDealtSequence: info.workTicketDealtSequence,
          })
        );
      }
      // 复核ID存在的话获取复核记录
      if (info.workTicketCheckSequence) {
        promiseList.push(
          this.queryWorkTicketCheck({
            workTicketCheckSequence: info.workTicketCheckSequence,
          })
        );
      }
      // srcList

      let dealJson = {
        orderInfo: $publicFun.clone(this.orderInfo),
        confirmInfo: $publicFun.clone(this.confirmInfo),
        handleInfo: $publicFun.clone(this.handleInfo),
        reviewInfo: $publicFun.clone(this.reviewInfo),
      };
      // 所有的调取完 ⬇⬇⬇
      Promise.all(promiseList).then((resList) => {
        resList.forEach((item) => {
          info = {
            ...item,
            ...info
          };
        });
        this.selectInfo = info;
        for (const key in dealJson) {
          const ele = dealJson[key];
          ele.forEach((item) => (item.value = info[item.key]));
          this[key] = ele;
        }
        this.dialogLoading = false;
        this.confirmInfoSrcList.push(this.$imgBaseUrl + dealJson.confirmInfo[3].value)
        this.handleInfoSrcList.push(this.$imgBaseUrl + dealJson.handleInfo[3].value)
      });
      this.waitConfirmwaitConfirm = true;
    },
    // 创建自定义窗口 ⬇⬇⬇
    createInfoWindow (data) {
      let box = document.createElement("div");
      box.className = "infoWindow";
      box.innerHTML = `
        <div class='infoBlock infoTitle'>${data.address}:${data.device_name}</div>
        <div class='infoBlock'>
          <div style="width:80px">设备类型:</div>
          <div>${data.deviceType}</div>
        </div>
        <div class='infoBlock'>
          <div style="width:80px">设备编号:</div>
          <div>${data.deviceId}</div>
        </div>
      `;
      return box;
    },
    // 查询设备信息
    queryDeviceInfo (info) {
      let params = {
        deviceType: info.deviceTypeCode,
        deviceId: info.deviceId,
      };
      if (this.marker != "") {
        this.map.remove(this.marker);
        this.map.clearInfoWindow();
      }
      if (this.waitConfirmwaitConfirm) this.handleClickDetail(info);
      this.$operateUnit.queryDeviceInfo(params).then((res) => {
        let data = res.resultEntity;
        let deviceTypeCode =
          info.deviceTypeCode == "lot_top_camera"
            ? "video"
            : info.deviceTypeCode;
        let statusType = {
          1: "fault",
          2: "fault",
          3: "offline",
        };
        let icon = require(`@/assets/maintenanceManagement/map-icon/${deviceTypeCode}-${statusType[info.deviceFaultTypeCode]
          }.png`);
        this.marker = new window.AMap.Marker({
          position: new window.AMap.LngLat(data.longitude, data.latitude),
          icon,
          // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
          offset: new window.AMap.Pixel(-13, -30),
        });
        this.map.setCenter([data.longitude, data.latitude]);
        var infoWindow = new window.AMap.InfoWindow({
          isCustom: true, //使用自定义窗体
          content: this.createInfoWindow({ ...info, ...data }),
          offset: new window.AMap.Pixel(16, -45),
        });
        this.marker.on("click", () => {
          infoWindow.open(this.map, this.marker.getPosition());
        });
        this.map.add([this.marker]);
      });
    },
    // 获取复核记录
    queryWorkTicketCheck (params) {
      return new Promise((r) => {
        this.$operateUnit.queryWorkTicketCheck(params).then((res) => {
          r(res.resultEntity);
        });
      });
    },
    // 获取处理记录
    queryWorkTicketDealt (params) {
      return new Promise((r) => {
        this.$operateUnit.queryWorkTicketDealt(params).then((res) => {
          res.resultEntity.handlePic = res.resultEntity.picUrl;
          r(res.resultEntity);
        });
      });
    },
    // 获取运维信息
    queryMaintenance (params) {
      return new Promise((r) => {
        this.$operateUnit.queryMaintenance(params).then((res) => {
          r(res.resultEntity);
        });
      });
    },
    // 工单计数
    queryWorkTicketCount () {
      let params = {
        deviceTypeCodeList: this.deviceCheckBox,
      };
      this.$operateWorkList.queryWorkTicketCount(params).then((res) => {
        let countJson = {},
          allCount = 0;
        res.resultEntity.forEach((item) => {
          countJson[item.workTicketStatus] = item.count;
          allCount = item.count + allCount
          // if (item.workTicketStatus != 4) allCount = item.count + allCount;
        });
        this.listStatus.forEach((item) => {
          item.count = countJson[item.key] ? countJson[item.key] : '0'
          if (item.label == "全部") {
            item.count = allCount;
          }
        });
      });
    },
    // 分页
    getVal (val) {
      this.pageNum = val;
      this.queryWorkTicketList();
    },
    // 查询设备列表
    queryWorkTicketList () {
      let info = {
        deviceTypeCodeList: this.deviceCheckBox, // 设备类型集合
        ticketStatusCodeList:
          this.tabsValue == "" ? [1, 2, 3, 4] : [this.tabsValue],
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        workTicketSequence: this.searchForm.workId,
      };
      this.$operateWorkList.queryWorkTicketList(info).then((res) => {
        this.total = res.resultEntity.total;
        this.listJson[this.tabsValue == "" ? 0 : this.tabsValue] =
          res.resultEntity.list;
        if (res.resultEntity.list[0]) this.queryDeviceInfo(res.resultEntity.list[0]);
        this.queryWorkTicketCount();
      });
    },
    // 多选框选中事件
    handleChangeCheckBox () { },
    // tab页切换事件
    changeStatus (key) {
      this.tabsValue = key;
      this.pageNum = 1;
    },
    // 初始化地图
    initMap () {
      let that = this;
      let themeType = {
        dark: 'amap://styles/a1ef87b8517f7bb18e73e47a65ac69c9',
        light: 'amap://styles/37bc333a00b6dc86dbd9309cae53802f'
      }
      this.map = new window.AMap.Map("map", {
        mapStyle: themeType[this.theme], // 设置地图的显示样式
        resizeEnable: true,
        expandZoomRange: true,
        zoom: 17,
        zooms: [3, 20],
        center: [106.562865, 29.600376],
        viewMode: "3D", //使用3D视图 
        pitch: 60,
      });
      //地图点击事件
      this.map.on("click", showInfoClick);
      function showInfoClick (e) {
        let lng = e.lnglat.getLng(); //获取经度
        let lat = e.lnglat.getLat(); //获取纬度
        that.lnglat = lng + "," + lat; //将经纬度绑定至input输入框
      }
    },
    // 查看设备类型
    queryTypeList () {
      let info = {
        columnName: ["value", "name"],
        tableName: "tb_dict",
        whereStr: [
          {
            colName: "parent_id",
            value: "9A5949F452B54BD9A96B9E978157CD1B",
          },
        ]
      };
      this.$queryDict.queryDict(info).then((res) => {
        this.deviceTypeList = res.resultEntity;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.mainbody {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  position: relative;
  /deep/ .el-dialog__wrapper {
    /deep/ .el-dialog {
      position: absolute;
      float: right;
      right: 100px;
    }
  }
  .topContent {
    width: 100%;
    height: 12%;
    display: flex;
    justify-content: space-around;
    padding-top: 0.5%;
    .topContent-item {
      width: 10%;
      height: 80%;
      background-image: url('../../../../assets/maintenanceManagement/dataBroadTop.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-family: PingFang-SC-Medium;
      .topContent-item-number {
        width: 100%;
        color: #fefeff;
        font-size: 24px;
        line-height: 40px;
      }
      .topContent-item-text {
        width: 100%;
        color: #70aee8;
        font-size: 16px;
      }
    }
  }
  .leftContent {
    width: 22%;
    height: 85%;
    margin-left: 2%;
    position: relative;
    .leftContent-input {
      width: 98%;
      margin-left: 1%;
      margin-top: 5px;
    }
    .leftContent-selectChoose {
      width: 100%;
      height: 120px;
      /deep/ .el-checkbox.is-bordered.el-checkbox--small .el-checkbox__inner {
        display: none !important;
      }
      .el-checkbox.is-bordered.el-checkbox--small {
        width: 88px;
        text-align: center;
        margin-left: 5px;
        margin-right: 0px;
        margin-top: 5px;
      }
      /deep/ .el-checkbox.el-checkbox--small.is-bordered.is-checked {
        border: 1px solid #019fe4 !important;
        .el-checkbox__label {
        }
      }
    }
    .leftContent-line {
      width: 100%;
      height: 1px;
      @include background(operateWorkListBottomBack);
      margin-top: 5px;
    }
    .pagination {
      position: absolute;
      right: 0;
      bottom: -5px;
    }
    .leftContent-listContent {
      height: 77%;
      padding-left: 2%;
      /deep/ .el-tabs__nav-scroll {
        overflow: hidden;
        display: flex;
        justify-content: space-around;
        color: white;
      }
      .listType {
        display: flex;
        justify-content: space-between;
        margin: 10px 0 30px;
        .listType-block-select {
          border-bottom: #019fe4 2px solid;
        }
        .listType-block {
          width: 25%;
          text-align: center;
          cursor: pointer;
          height: 14px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #70aee8;
          padding-bottom: 10px;
        }
      }
      .leftContent-listContent-item {
        width: 100%;
        height: 100%;
        margin-top: 5px;
        cursor: pointer;
        .lineOne {
          height: 15px;
          line-height: 15px;
          .lineOne-left {
            font-family: PingFang-SC-Medium;
            font-size: 14px;
            @include font_color(operateWorkListItemTopTitle);
          }
          .lineOne-right {
            font-family: PingFang-SC-Regular;
            font-size: 14px;
            color: #12afff;
          }
        }
        .lineTwo {
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          margin-top: 5px;
          .label {
            @include font_color(operateWorkListItemLabel);
          }
          .value {
            @include font_color(operateWorkListItemValue);
            margin-left: 10px;
          }
        }
        .lineThree {
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          margin-top: 5px;
          .label {
            @include font_color(operateWorkListItemLabel);
          }
          .value {
            @include font_color(operateWorkListItemValue);
            margin-left: 10px;
          }
        }
        .lineFour {
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          margin-top: 5px;
          .label {
            @include font_color(operateWorkListItemLabel);
          }
          .value {
            @include font_color(operateWorkListItemValue);
            margin-left: 10px;
          }
        }
        .lineFive {
          margin-top: 5px;
          .popTimeLine {
            position: relative;
            width: 100%;
            height: 50px;
            .timeLineLine {
              position: absolute;
              left: 0px;
              top: 8px;
              height: 5px;
              width: 100%;
              border-radius: 5px;
              @include background(operateWorkListTimelineBack);
            }
            .timeLineItemBox {
              position: absolute;
              left: 0px;
              top: 0px;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
              width: 100%;
              height: 100%;
              .timeLineItem {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                width: 20%;
                height: 100%;
                .poitBg {
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  top: calc(50% - 25px);
                  left: calc(50% - 12px);
                  border-radius: 50%;
                  @include background(operateWorkListTimelineRoundBack);
                  z-index: 10;
                }
                .poitGreen {
                  position: absolute;
                  top: calc(50% - 20px);
                  left: calc(50% - 7px);
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #00d03f;
                  z-index: 11;
                }
                .poit {
                  position: absolute;
                  top: calc(50% - 20px);
                  left: calc(50% - 7px);
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #70aee8;
                  z-index: 11;
                }
                .tip {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  @include font_color(operateWorkListTimelineTip);
                  line-height: 20px;
                  margin-top: 10px;
                  margin-left: -5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .map-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    #map {
      width: 100%;
      height: 100%;
    }
  }
  /deep/ .infoWindow {
    background: rgba(0, 52, 99, 0.9);
    border: 1px solid #0f81a2;
    border-radius: 12px;
    padding: 20px 15px 0;
    .infoTitle {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
    }
    .infoBlock {
      display: flex;
      margin-bottom: 16px;
      & > div:first-child {
        font-weight: bold;
        color: #70aee8;
      }
      & > div:last-child {
        font-weight: bold;
        color: #bae8ff;
      }
    }
  }
}
.dialogDetails {
  max-height: 650px;
  overflow: auto;
  .dialogDetailsTitle {
    display: flex;
    justify-content: space-between;
    .dialogDetailsTitleLeft {
      @include font_color(operateWorkListDialogTitle);
    }
    & > div:last-child:hover {
      cursor: pointer;
    }
    & > div:last-child {
      color: #12afff;
    }
  }
  .dialogDetailsBlock {
    color: white;
    .dialogDetailsRow {
      display: flex;
      margin-bottom: 15px;
      & > img {
        width: 120px;
        height: 90px;
      }
      .dialogDetailsRowTitle {
        @include font_color(operateWorkListDialogLabel);
      }
      .dialogDetailsRowContent {
        @include font_color(operateWorkListDialogValue);
      }
    }
  }
}
/*滚动条样式*/
.dialogDetails::-webkit-scrollbar {
  width: 4px;
}
.dialogDetails::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.dialogDetails::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}
</style>